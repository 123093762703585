/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-10-11",
    versionChannel: "nightly",
    buildDate: "2023-10-11T00:22:14.322Z",
    commitHash: "0ef28604b16bb9b27ee9291f527a1827d7713b7b",
};
